import React, { useState, useEffect } from 'react';
import axios from 'axios'

import { Card, Button, Switch, Row, Col } from 'antd'

import ReactPlayer from 'react-player'

import './App.css'

const url = 'https://cdn.jsdelivr.net/gh/cnly1987/cdn@master/tiktok.json'

const getVideo = () => {
    const timestrip = new Date().getTime()
    return axios.get(url + '?timestrip=' + timestrip)
}

const random = (items, callback)=>{
    let d =  items[Math.floor(Math.random() * items.length)] 
    return callback?callback(d):d
}

const VideoPlayer = (porps) => {
    document.title = 'Tiktok random'
    const [state, setState] = useState({
        havePlayed: [],
        videos: [],
        lodaing: false,
        url: '',
        isGoon: true
    })


    useEffect(() => {
        getVideo().then(res => { 
            setState(state => ({
                ...state,
                url:random(res.data),
                videos: res.data, 
            }))

        })
    }, [])


    const getRandom = () => {
        setState(state => ({...state, loading: true }))
        
        random(state.videos, (d)=>{
            setState(state => ({
                ...state,
                loading:false,
                url: d,
            }))
        })
    }

    const goplay = () => {
        if (state.isGoon) {
            getRandom()
        }
    }

    const changePlayer = (checked) => {
        setState(state => ({
            ...state,
            isGoon: checked,
        }))
    }


    return ( <div className="container">
            <Row >
            <Col md = {{ span: 18 } }  xs = {   { span: 24 }  } >
                <ReactPlayer 
                    url = { state.url }
                    controls = { true }
                    playing = { true }
                    onEnded = { goplay }
                    className = 'react-player'
                    width = '100%'
                    height = '70vh'
                />
            </Col> 
            <Col md = {   { span: 4, offset: 2 }  }  xs = {  { span: 24 } } >
            <Card title >
            <Row >
            <Col md = {  { span: 24 }  }  xs = {   { span: 12 }  }  className = "bottomMargin" >
                <Button htmlType = "button"
                    type = "primary"
                    className = "green"
                    onClick = { getRandom }
                    loading = { state.loading } > 随机换一个 
                </Button> 
            </Col> 
            <Col md = {
                { span: 24 }
            }
            xs = {
                { span: 12 }
            } >
                <Switch 
                    checkedChildren = "自动随机播放"
                    unCheckedChildren = "自动随机播放"
                    defaultChecked onChange = { changePlayer }
                /> 
            </Col> 
            </Row>

            </Card> 
            </Col>

            </Row>

        </div>
    )

}

export default VideoPlayer;